import "./App.css";
import { RampInstantSDK } from "@ramp-network/ramp-instant-sdk";

function App() {
  new RampInstantSDK({
    hostLogoUrl: "https://www.primequotes.com/img/prime-logo.svg",
    hostApiKey: "6abws2yd58svxp5rhvzhtxxoknk8os8nvszahdka",
    hostAppName: "PrimeQuotes Payment",
    swapAsset: "USDT",
    fiatCurrency: "USD",
    userAddress: "0xcd42ea337c1a7a895bcba063904e304699f4ad13",
    userEmailAddress: "metin@primequotes.com",
  }).show();

  return (
    <div className="mt-4">
      <div class="leading-loose mt-4"></div>
    </div>
  );
}

export default App;
